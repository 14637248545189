button {
    &.button-with-icon {
        .mat-mdc-button-persistent-ripple,
        .mat-mdc-button-ripple {
            top: 0;
            bottom: -11px;
            left: 0;
            right: -9px;
            position: absolute;
        }
    }
    &.icon-only {
        background-color: transparent;
        color: black !important;
    }
}
